'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createPlugin;
function createPlugin(logrocket) {
  var sanitizer = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (action) {
    return action;
  };

  return function (store) {
    store.subscribe(function (mutation) {
      var sanitized = sanitizer(mutation);

      if (sanitized) {
        logrocket.log('mutation', sanitized);
      }
    });
  };
}
module.exports = exports['default'];